import { Mutex } from 'async-mutex';
import { AxiosError } from 'axios';
import { UiDictionariesClient } from '~/app-modules/core/clients/ui-dictionaries-client';
import { StrictMap } from '~/app-modules/core/utils/strict-map';
import { UiDictionaryType } from '~/app-modules/core/enums/UiDictionaryType';

@singleton()
export class LocalizationsStore {
  constructor(private _uiDictionariesClient: UiDictionariesClient) {}

  private _mutex = new Mutex();

  private _dictionary = shallowReactive(new StrictMap<UiDictionaryType, Map<string, string>>());

  async getDictionaryByType(type: UiDictionaryType): Promise<Map<string, string>> {
    if (this._dictionary.size !== 0) return this._dictionary.strictGet(type);

    await this.loadDictionaries();
    return this._dictionary.strictGet(type);
  }

  async loadDictionaries() {
    if (this._mutex.isLocked()) return await this._mutex.waitForUnlock();

    try {
      await this._mutex.runExclusive(async () => {
        const uiDictionaries = await this._uiDictionariesClient.getUiDictionaries();
        for (const [type, dict] of Object.entries(uiDictionaries)) {
          this._dictionary.set(type as UiDictionaryType, new StrictMap<string, string>(dict));
        }
      });
    } catch (error) {
      if (!(error instanceof AxiosError)) {
        throw error;
      }
    }
  }
}
