<template>
  <v-dialog
    v-model="showDialog"
    :persistent="loading || busy || persistent"
    transition="dialog-bottom-transition"
    :width="width"
    :min-width="minWidth"
    :max-width="maxWidth"
    class="x-dialog"
  >
    <x-card
      class="pa-4"
      content-color="beton"
      content-class="x-dialog__content"
    >
      <template v-if="loading">
        <div class="py-6 d-flex justify-center">
          <spinner class="x-dialog__spinner" />
        </div>
      </template>

      <template v-else>
        <!--  top header with buttons  -->
        <div>
          <div class="d-flex flex-nowrap justify-start align-center mb-2">
            <h2 class="text-beton mr-1">
              {{ title }}
            </h2>

            <div class="ml-auto">
              <x-button
                v-if="closable"
                theme="transparent"
                icon="$close"
                compact
                :disabled="busy"
                @click="onClose"
              />
            </div>
          </div>
          <v-divider />

          <div class="d-flex align-start my-5">
            <span
              v-if="subtitle"
              class="text-h4 text-beton mb-2 mr-2"
            >
              {{ subtitle }}
            </span>
            <slot name="badge" />
          </div>
        </div>

        <!--  content in box card  -->
        <v-sheet
          v-if="$slots.default"
          ref="content"
          class="overflow-auto my-4"
          style="max-height: calc(100vh - 178px)"
        >
          <slot />
        </v-sheet>

        <div class="justify-end d-flex">
          <slot name="handles" />
        </div>
      </template>
    </x-card>
  </v-dialog>
</template>

<script setup lang="ts">
import Spinner from '~/app-modules/core/components/icons/lg-spinner.vue';

defineOptions({
  name: 'XDialog',
});

const {
  title = '',
  subtitle,
  loading = false,
  busy = false,
  closable = true,
  persistent = false,
  width = 'unset',
  minWidth = 'unset',
  maxWidth = 'unset',
} = defineProps<{
  title?: string;
  subtitle?: string;
  busy?: boolean;
  loading?: boolean;
  closable?: boolean;
  persistent?: boolean;
  width?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
}>();

const showDialog = defineModel<boolean>({ default: false });

function onClose() {
  showDialog.value = false;
}
</script>

<style scoped lang="scss">
.x-dialog {
  backdrop-filter: blur(8px);

  .v-overlay__scrim {
    background: #fff;
    opacity: 0.8;
  }

  &__spinner {
    @include animate-spinner();
  }

  .x-card {
    position: unset;
  }
}
</style>
