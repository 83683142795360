<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.41789 10.3321C3.02737 9.94158 3.02737 9.30842 3.41789 8.91789C3.80842 8.52737 4.44158 8.52737 4.83211 8.91789L7.1875 11.2733L7.1875 3.3125C7.1875 2.79473 7.60723 2.375 8.125 2.375C8.64277 2.375 9.0625 2.79473 9.0625 3.3125L9.0625 11.2733L11.4179 8.91789C11.8084 8.52737 12.4416 8.52737 12.8321 8.91789C13.2226 9.30842 13.2226 9.94158 12.8321 10.3321L8.83211 14.3321C8.44158 14.7226 7.80842 14.7226 7.41789 14.3321L3.41789 10.3321Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
