<template>
  <div class="error-page-overlay">
    <waves-background />
    <div class="container">
      <!--  404  -->
      <div v-if="currentError?.statusCode === 404">
        <p class="code my-6">{{ currentError.statusCode }}</p>
        <p class="title my-2">Страница не найдена</p>
        <p class="description my-2">{{ currentError?.message }}</p>
      </div>

      <!--  403  -->
      <div v-else-if="currentError?.statusCode === 403">
        <p class="code my-6">{{ currentError.statusCode }}</p>
        <p class="title my-2">Доступ к запрашиваемому ресурсу запрещён</p>
        <p class="description my-2">{{ currentError?.message }}</p>
      </div>

      <!--  Остальные  -->
      <div v-else>
        <p class="code my-6">{{ currentError?.statusCode || 500 }}</p>
        <p class="title">При загрузке страницы произошла ошибка</p>
        <p class="description my-2">{{ currentError?.message }}</p>
      </div>

      <v-btn
        class="font-weight-bold my-6"
        color="primary"
        :text="currentError?.data?.redirectLink?.label || 'Вернуться домой'"
        @click="redirect()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app';
import * as Sentry from '@sentry/vue';

const { error } = defineProps<{ error: NuxtError }>();

const currentError =
  error ||
  createError({
    statusCode: 500,
    message: 'Необработанная ошибка, обратитесь в службу поддержки',
  });

console.error('Page error:', currentError?.statusCode, currentError?.data);

try {
  Sentry.captureException(error);
} catch (e) {
  console.error('Could not log Sentry error', e);
}

useHead({
  title: `Упс... Ошибка:${currentError?.statusCode || 500}`,
});

function redirect() {
  clearError({ redirect: currentError?.data?.redirectLink?.to || '/' }).then(() =>
    window.location.reload()
  );
}
</script>

<style lang="scss" scoped>
.error-page-overlay {
  position: absolute;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
}

.code {
  font-style: normal;
  font-weight: 200;
  font-size: 150px;
  line-height: 150px;

  text-align: center;

  color: #1e598f;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;

  text-align: center;

  color: #1e588fbe;
}

.description {
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;

  text-align: center;

  color: #52575b;
}

.container {
  position: absolute;
  height: 70vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  @media (max-height: 460px) {
    height: 100%;
    justify-content: unset;
  }
}
</style>
