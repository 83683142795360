<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="13"
    fill="none"
    viewBox="0 0 16 13"
  >
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M15.154 4.11c0 .817-.145 1.52-.436 2.11A3.893 3.893 0 0 0 13.364 6c-.158 0-.31.008-.459.023.109-.14.202-.302.28-.484.17-.388.254-.865.254-1.43s-.084-1.041-.254-1.43c-.166-.387-.402-.682-.707-.882-.304-.2-.661-.3-1.07-.3-.409 0-.766.1-1.07.3-.305.2-.542.495-.711.883-.167.388-.25.864-.25 1.43 0 .564.083 1.041.25 1.43.17.387.406.681.71.882.255.167.546.265.874.293-.16.14-.297.3-.413.48a2.2 2.2 0 0 0-.248.524.56.56 0 0 0 .025.42 3.602 3.602 0 0 1-1.081-.405 3.448 3.448 0 0 1-1.34-1.402c-.328-.612-.492-1.353-.492-2.223 0-.872.164-1.614.492-2.226A3.422 3.422 0 0 1 9.494.484C10.059.161 10.697 0 11.408 0c.706 0 1.341.161 1.906.484.568.32 1.016.787 1.344 1.399.33.612.496 1.354.496 2.226Zm-8.876-4c.465 0 .842.376.842.841v6.2a.958.958 0 0 1-1.747.545L2.18 3.074h-.06v4.19a.846.846 0 0 1-1.691 0V1.082a.972.972 0 0 1 1.774-.55L5.366 5.14h.07V.95c0-.465.377-.842.842-.842Z"
      clip-rule="evenodd"
    />
    <path
      stroke-width=".15"
      stroke="none"
      d="M13.524 9.153a6.5 6.5 0 0 1-.367.38l-1.566 1.45a.591.591 0 0 0 .401 1.025h2.533a.496.496 0 0 0 0-.99h-1.473l.612-.6c.335-.306.597-.568.783-.789a2.35 2.35 0 0 0 .395-.617 1.51 1.51 0 0 0 .115-.572c0-.294-.076-.557-.229-.788a1.52 1.52 0 0 0-.636-.534 2.162 2.162 0 0 0-.943-.193c-.348 0-.66.066-.931.2a1.52 1.52 0 0 0-.637.572c-.07.113-.123.235-.16.366a.42.42 0 0 0 .1.41.542.542 0 0 0 .396.168h.073a.52.52 0 0 0 .348-.144c.093-.083.168-.191.224-.297a.548.548 0 0 1 .226-.229.73.73 0 0 1 .354-.08.76.76 0 0 1 .349.077.568.568 0 0 1 .322.541.756.756 0 0 1-.07.327 1.39 1.39 0 0 1-.22.317Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
  stroke: currentColor;
}
</style>
