<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.25 3.75C17.4581 3.75 18.4375 4.72938 18.4375 5.9375V13.4375C18.4375 14.6456 17.4581 15.625 16.25 15.625H6.62847C6.56677 15.625 6.50646 15.6433 6.45513 15.6775L3.40871 17.7084C2.61955 18.2345 1.5625 17.6688 1.5625 16.7204V5.9375C1.5625 4.72938 2.54188 3.75 3.75 3.75H16.25ZM5.625 7.5C5.10723 7.5 4.6875 7.91973 4.6875 8.4375C4.6875 8.95527 5.10723 9.375 5.625 9.375H14.375C14.8928 9.375 15.3125 8.95527 15.3125 8.4375C15.3125 7.91973 14.8928 7.5 14.375 7.5H5.625ZM4.6875 10.9375C4.6875 10.4197 5.10723 10 5.625 10H11.25C11.7678 10 12.1875 10.4197 12.1875 10.9375C12.1875 11.4553 11.7678 11.875 11.25 11.875H5.625C5.10723 11.875 4.6875 11.4553 4.6875 10.9375Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
