import { singleton } from 'tsyringe';
import { TTokensPairDto } from '~/app-modules/auth/clients/dto/tokens-pair.dto';

export interface ITokensPair {
  accessToken: string;
  refreshToken: string;
  updated: Date;
}

@singleton()
export class TokensStore {
  constructor() {
    this._tokens = useCookie('fishplan-farm-auth', { default: () => null });
  }

  protected _tokens: Ref<ITokensPair | null>;

  setTokens(tokensPair: TTokensPairDto) {
    this.dropTokens();
    this._tokens.value = {
      accessToken: tokensPair.accessToken,
      refreshToken: tokensPair.refreshToken,
      updated: new Date(),
    };
  }

  get tokens(): ITokensPair | null {
    return this._tokens.value;
  }

  dropTokens() {
    this._tokens.value = null;
  }

  get accessToken(): string {
    return `Bearer ${this._tokens.value?.accessToken || ''}`;
  }

  get refreshToken(): string {
    return `${this._tokens.value?.refreshToken || ''}`;
  }
}
