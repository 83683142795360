<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="11"
    fill="none"
    viewBox="0 0 15 11"
  >
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M7.376 7.154a.846.846 0 0 0 1.692 0V4.695h3.434v2.461a.844.844 0 1 0 1.687 0V.844a.844.844 0 0 0-1.688 0V3.3H9.069V.846a.846.846 0 1 0-1.692 0v6.308ZM.714 9.418a.832.832 0 1 0 1.664 0V7.039h.051c.076.167.185.332.328.496.144.162.327.296.551.402.227.107.501.16.824.16.456 0 .868-.117 1.235-.35.37-.238.661-.586.875-1.044.216-.46.324-1.026.324-1.695 0-.688-.11-1.26-.332-1.715-.221-.458-.517-.8-.887-1.027a2.255 2.255 0 0 0-1.21-.344c-.337 0-.618.057-.845.172a1.668 1.668 0 0 0-.546.422 2.18 2.18 0 0 0-.317.492h-.074V2.82a.82.82 0 1 0-1.64 0v6.598Zm1.778-3.484A2.67 2.67 0 0 1 2.343 5c0-.357.05-.667.149-.93.099-.263.24-.466.425-.61a1.1 1.1 0 0 1 .688-.214c.273 0 .504.074.691.223.188.148.33.354.426.617.097.263.145.568.145.914 0 .349-.05.658-.149.926a1.387 1.387 0 0 1-.426.625 1.073 1.073 0 0 1-.687.222 1.08 1.08 0 0 1-.684-.218 1.387 1.387 0 0 1-.43-.621Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
  stroke: currentColor;
}
</style>
