import { Mutex } from 'async-mutex';
import { AxiosError } from 'axios';
import { AuthService } from '~/app-modules/auth/services/auth.service';
import { UserClient } from '~/app-modules/user/clients/user.client';
import { TUserDto } from '~/app-modules/user/clients/dto/user.dto';

@singleton()
export class UserStore {
  constructor(private _authService: AuthService, private _userClient: UserClient) {
    watchEffect(async () => {
      // авторизация потеряна
      if (!_authService.isLoggedIn) {
        this._currentUser.value = undefined;
      } else if (_authService.isLoggedIn) {
        // авторизация получена
        await this.loadUser();
      }
    });
  }

  private _mutex = new Mutex();
  private _currentUser = ref<TUserDto>();

  get currentUser(): TUserDto | undefined {
    return this._currentUser.value;
  }

  get fullName(): string {
    if (!this._currentUser.value) return '';

    const { lastName, firstName, middleName } = this._currentUser.value;
    return `${lastName} ${firstName}${middleName ? ` ${middleName}` : ''}`;
  }

  get organizationName(): string {
    return this._currentUser.value?.organizationName || '';
  }

  async loadUser() {
    if (this._mutex.isLocked()) return await this._mutex.waitForUnlock();

    try {
      await this._mutex.runExclusive(async () => {
        this._currentUser.value = await this._userClient.getMe();
      });
    } catch (error) {
      if (!(error instanceof AxiosError)) {
        throw error;
      }
    }
  }

  subscribeUserChanged(onUserChanged: (user: TUserDto | undefined) => void) {
    return watchEffect(() => {
      onUserChanged(this.currentUser);
    });
  }
}
