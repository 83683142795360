<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.75 8.00721C3.75 7.45398 4.19848 7.0055 4.7517 7.0055L7.99994 7.00552C7.44671 7.00552 8.5017 6.99998 7.99994 7.00552L11.2483 7.00551C11.8016 7.00551 12.2501 7.45399 12.2501 8.00721C12.2501 8.56044 11.8016 9.00892 11.2483 9.00892L7.99994 9.00893L4.7517 9.00891C4.19848 9.00891 3.75 8.56043 3.75 8.00721Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
