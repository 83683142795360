<template>
  <x-dialog
    v-model="visible"
    :title="title"
    :subtitle="message"
    :persistent="persistent"
    :closable="closable"
    :width="width"
    :min-width="minWidth"
    :max-width="maxWidth"
    :class="customClass"
    :style="customStyle"
    @update:model-value="onUpdateVisible"
  >
    <template #handles>
      <x-button
        v-if="showCancelButton"
        :label="cancelButtonText"
        :theme="cancelButtonTheme"
        :color="cancelButtonColor"
        :disabled="cancelButtonDisabled"
        class="flex-0-0"
        :class="{
          'mr-2': showConfirmButton,
        }"
        @click="onCancel"
      />
      <x-button
        v-if="showConfirmButton"
        :label="confirmButtonText"
        :theme="confirmButtonTheme"
        :color="confirmButtonColor"
        :disabled="confirmButtonDisabled"
        class="flex-0-0"
        @click="onConfirm"
      />
    </template>
  </x-dialog>
</template>

<script setup lang="ts">
import { ConfirmStatus } from '~/app-modules/core/enums/ConfirmStatus';
import { DialogOptions } from '~/app-modules/core/composables/dialog-options-type';

defineOptions({
  name: 'XConfirmDialog',
});

const visible = ref(false);

const {
  cancelButtonText = 'Отменить',
  confirmButtonText = 'Подтверждаю',
  cancelButtonTheme = 'outlined',
  confirmButtonTheme = 'colored',
  cancelButtonColor = '',
  confirmButtonColor = 'blue',
  customClass = '',
  customStyle = {},
  title = '',
  message = '',
  closable = true,
  persistent = true,
  width = 'unset',
  minWidth = 'unset',
  maxWidth = 'unset',
  showCancelButton = true,
  showConfirmButton = true,
  cancelButtonDisabled = false,
  confirmButtonDisabled = false,
} = defineProps<DialogOptions>();

defineExpose({ open });

let resolveFn = (_status: ConfirmStatus) => {};

function onUpdateVisible(newVisible: boolean) {
  // обработка закрытия через крестик или клик снаружи
  if (!newVisible) {
    onClose();
  }
}

function onClose() {
  visible.value = false;
  // Сначала дожидаемся закрытия окна
  nextTick(() => {
    resolveFn(ConfirmStatus.closed);
  });
}

function onCancel() {
  visible.value = false;
  // Сначала дожидаемся закрытия окна
  nextTick(() => {
    resolveFn(ConfirmStatus.canceled);
  });
}

function onConfirm() {
  visible.value = false;
  // Сначала дожидаемся закрытия окна
  nextTick(() => {
    resolveFn(ConfirmStatus.confirmed);
  });
}

function open(): Promise<ConfirmStatus> {
  visible.value = true;
  return new Promise((resolve) => {
    resolveFn = resolve;
  });
}
</script>

<style lang="scss" scoped></style>
