import { TUserDto } from '~/app-modules/user/clients/dto/user.dto';
import { ApplicationLogger } from '~/app-modules/core/logger/application-logger';
import { parseString } from '~/app-modules/core/utils/validation.helpers';
import { ConfigProvider } from '~/app-modules/core/ioc/config.provider';
import { BaseEvent } from '~/app-modules/metrics/services/events/base.event';

@singleton()
export class YandexMetrikaProvider {
  constructor(private _logger: ApplicationLogger, private _configProvider: ConfigProvider) {}

  private _id: string = null;
  private _isInitializing = false;
  private _isInitialized = false;

  public init() {
    if (this._isInitializing || this._isInitialized) {
      this._logger.error('Yandex metrika initialization has been called more than once');
      return;
    }

    try {
      this._isInitializing = true;
      const { yandexMetrika } = this._configProvider.getPublicRuntimeConfig();
      this._id = parseString({ yandexMetrikaId: yandexMetrika.token });
      /* eslint-disable */
      const options = {
        id: this._id,
        metrikaUrl: 'https://mc.yandex.ru/metrika/tag.js',
        accurateTrackBounce: true,
        childIframe: false,
        clickmap: true,
        defer: false,
        trackHash: false,
        trackLinks: true,
        type: 0,
        webvisor: false,
        triggerEvent: false,
      };

      (function (m, e, t, r, i, k, a) {
        m[i] =
          m[i] ||
          function () {
            (m[i].a = m[i].a || []).push(arguments);
          };
        m[i].l = 1 * new Date();
        k = e.createElement(t);
        a = e.getElementsByTagName(t)[0];
        k.async = 1;
        k.src = r;
        a.parentNode.insertBefore(k, a);
      })(window, document, 'script', options.metrikaUrl, 'ym');
      /* eslint-enable */
      this._ym('init', options);
      this._logger.log(
        `Yandex Metrika initialized, ID=${this._id}. Options: ${JSON.stringify(options)}`
      );
      this._isInitialized = true;
      this._isInitializing = false;
    } catch (e) {
      this._logger.error('Yandex metrika initialization failed', e);
    }
  }

  public trackNavigation(from: string, to: string) {
    try {
      const args = {
        referer: from,
      };

      this._ym('hit', to, args);
      this._logger.log(`Yandex Metrika page hit: "${to}" (referer="${from}")`);
    } catch (e) {
      this._logger.error('Failed to track navigation', e);
    }
  }

  public trackSignedUser(user: TUserDto) {
    if (!user || !user.userId || !user.organizationId) {
      this._logger.error(`Failed to track user: ${JSON.stringify(user)}`);
      return;
    }

    try {
      const userParams = {
        UserID: user.userId,
        OrganizationID: user.organizationId,
        OrganizationName: user.organizationName,
        UserFullName: `${user.lastName} ${user.firstName} ${user.middleName}`,
      };

      this._ym('userParams', userParams);
      this._logger.log(`Yandex Metrika userParams track: '$${JSON.stringify(userParams)}'`);
    } catch (e) {
      this._logger.error('Failed to track userParams', e);
    }
  }

  public trackEvent(event: BaseEvent) {
    try {
      if (
        (typeof event.type as unknown) !== 'string' ||
        [...event.type].find((c) => '/\\&#?="'.includes(c)) !== undefined
      ) {
        this._logger.error(`Failed to track event, unsupported event type: ${event.type}`);
        return;
      }

      this._ym('reachGoal', event.type, event.eventParams);
      this._logger.log(
        `Yandex Metrika reach goal: '${event.type}' with args '${JSON.stringify(
          event.eventParams
        )}'`
      );
    } catch (e) {
      this._logger.error(`Failed to track event: '${event.type}''`, e);
    }
  }

  private _ym(method: string, ...args: any[]) {
    const ym = (window as Window).ym;
    if (ym && (this._isInitialized || method === 'init')) {
      // eslint-disable-next-line
      ym.apply(null, [this._id, method, ...args]);
      if (args.length === 0) {
        this._logger.log(`Yandex Metrika call: ym("${this._id}", "${method}")`);
      } else {
        const argumentsText = args.map((a) => JSON.stringify(a)).join(', ');
        this._logger.log(`Yandex Metrika call: ym("${this._id}", "${method}", ${argumentsText})`);
      }
    } else if (args.length === 0) {
      this._logger.log(
        `Yandex Metrika is not initialized! Failed to execute: ym("${this._id}", "${method}")`
      );
    } else {
      const argumentsText = args.map((a) => JSON.stringify(a)).join(', ');
      this._logger.log(
        `Yandex Metrika is not initialized! Failed to execute: ym("${this._id}", "${method}", ${argumentsText})`
      );
    }
  }
}

declare global {
  interface Window {
    ym: (id: string, method: string, ...args: any[]) => void;
  }
}
