import * as Sentry from '@sentry/vue';
import { Options } from '@sentry/vue/types/types';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const vueApp = nuxtApp.vueApp;

  const { sentry } = useRuntimeConfig().public;

  const configuration: Partial<Options> = {
    enabled: sentry.enabled,
    app: vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  };

  // Replay - "Запись" экрана (https://docs.sentry.io/platforms/javascript/session-replay/)
  if (sentry.replay.enabled && Array.isArray(configuration.integrations)) {
    configuration.integrations.push(
      Sentry.replayIntegration({
        // mask: ['.any_selector'], // Для более тонкой настройки маскирования
        // unmask: ['.sentry-unmask'], // Для более тонкой настройки маскирования
        maskAllText: sentry.replay.maskAllText,
        blockAllMedia: sentry.replay.blockAllMedia,
        networkDetailAllowUrls: sentry.replay.detailsAllowUrls.split(', '),
        networkRequestHeaders: ['Cache-Control'],
        networkResponseHeaders: ['Referrer-Policy'],
      })
    );
  }

  Sentry.init(configuration);
});
