<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="13"
    fill="none"
    viewBox="0 0 17 13"
  >
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M6.977.842a.842.842 0 0 0-1.684 0v4.19h-.07L2.06.421a.972.972 0 0 0-1.774.55v6.182a.846.846 0 0 0 1.691 0v-4.19h.059L5.23 7.588a.958.958 0 0 0 1.747-.545v-6.2Zm1.068 6.312a.846.846 0 0 0 1.69 0V4.695h3.434v1.352c.102-.03.21-.047.318-.047h1.101c.097 0 .188.022.269.061V.844a.844.844 0 0 0-1.688 0V3.3H9.736V.846a.846.846 0 1 0-1.691 0v6.308Z"
      clip-rule="evenodd"
    />
    <path
      stroke-width=".15"
      stroke="none"
      d="m12.453 10.174 1.158-1.833v1.833h-1.158Zm-.527.99h1.666v.296a.576.576 0 1 0 1.153 0v-.295h.12a.495.495 0 1 0 0-.991h-.12V7.62a.695.695 0 0 0-.695-.695h-.364a.674.674 0 0 0-.569.313l-1.795 2.828a.715.715 0 0 0 .604 1.099Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
  stroke: currentColor;
}
</style>
