import { AxiosError } from 'axios';
import { Mutex } from 'async-mutex';
import { TokensStore } from '../stores/tokens.store';
import { AuthClient } from '~/app-modules/auth/clients/auth.client';
import { TTokensPairDto } from '~/app-modules/auth/clients/dto/tokens-pair.dto';
import { UserStore } from '~/app-modules/user/stores/user.store';

@singleton()
export class AuthService {
  constructor(private _tokensStore: TokensStore, private _authClient: AuthClient) {}

  private _mutex = new Mutex();

  get Bearer(): String {
    return this._tokensStore.accessToken;
  }

  get isLoggedIn(): boolean {
    return this._tokensStore.tokens !== null;
  }

  async authorizeBySsoCode(authorizationCode: string) {
    try {
      if (this.isLoggedIn) {
        this.logout();
      }
      const tokensPair = await this._authClient.authorizeBySsoCode(authorizationCode);
      this._tokensStore.setTokens(tokensPair);
      //
    } catch (error) {
      if (error instanceof AxiosError) {
        // TODO: уточнить ошибки
        if (error.response?.status === 400) {
          useToast().error('Авторизация через SSO недоступна');
          throw error;
        } else {
          console.log(error);
          throw error;
        }
      } else {
        throw error;
      }
    }
  }

  async getSsoSignInUrl(backUrl: string, userStore?: UserStore) {
    // TODO: как получить круговую зависимость UserStore
    try {
      const { url } = await this._authClient.getSsoSignInUrl();

      const confirmCookie = useCookie('fishplan-farm-cookie-confirm');

      if (this.isLoggedIn && userStore && userStore.currentUser) {
        const { userId, organizationId } = userStore.currentUser;
        return `${url}&backUrl=${backUrl}&userId=${userId}&organizationId=${organizationId || ''}${
          confirmCookie ? '&fishplan-cookie-confirm=true' : ''
        }`;
      }

      return `${url}&backUrl=${backUrl}${confirmCookie ? '&fishplan-cookie-confirm=true' : ''}`;
      //
    } catch (error) {
      if (error instanceof AxiosError) {
        // TODO: уточнить ошибки
        if (error.response?.status === 400) {
          useToast().error('Авторизация через SSO недоступна');
          throw error;
        } else {
          console.log(error);
          throw error;
        }
      } else {
        throw error;
      }
    }
  }

  async getSsoSignUpUrl() {
    try {
      // TODO: разработка api метода для getSsoSignUpUrl
      const { url } = await this._authClient.getSsoSignInUrl();
      return url.split('/auth/')[0] + '/registration/user/';
      //
    } catch (error) {
      if (error instanceof AxiosError) {
        useToast().error('Регистрация недоступна');
        throw error;
      } else {
        throw error;
      }
    }
  }

  updateTokens(tokensPair: TTokensPairDto) {
    this._tokensStore.setTokens(tokensPair);
  }

  async ensureTokensRefreshed() {
    if (this._mutex.isLocked()) return await this._mutex.waitForUnlock();

    try {
      if (!this._tokensStore.refreshToken) return;

      await this._mutex.runExclusive(async () => {
        const tokensPair = await this._authClient.refresh(this._tokensStore.refreshToken);
        this._tokensStore.setTokens(tokensPair);
      });
    } catch (error) {
      console.error(error);
      this.logout();
      useNuxtApp().$router.push('/auth/');
    }
  }

  logout() {
    this._tokensStore.dropTokens();
  }
}
