<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4375 5.9375C18.4375 4.72938 17.4581 3.75 16.25 3.75H3.75C2.54188 3.75 1.5625 4.72938 1.5625 5.9375V16.7204C1.5625 17.6688 2.61955 18.2345 3.40871 17.7084L6.45513 15.6775C6.50646 15.6433 6.56677 15.625 6.62847 15.625H16.25C17.4581 15.625 18.4375 14.6456 18.4375 13.4375V5.9375ZM16.25 5.625C16.4226 5.625 16.5625 5.76491 16.5625 5.9375V13.4375C16.5625 13.6101 16.4226 13.75 16.25 13.75H6.62847C6.1966 13.75 5.7744 13.8778 5.41506 14.1174L3.4375 15.4358V5.9375C3.4375 5.76491 3.57741 5.625 3.75 5.625H16.25ZM4.6875 8.4375C4.6875 7.91973 5.10723 7.5 5.625 7.5H14.375C14.8928 7.5 15.3125 7.91973 15.3125 8.4375C15.3125 8.95527 14.8928 9.375 14.375 9.375H5.625C5.10723 9.375 4.6875 8.95527 4.6875 8.4375ZM5.625 10C5.10723 10 4.6875 10.4197 4.6875 10.9375C4.6875 11.4553 5.10723 11.875 5.625 11.875H11.25C11.7678 11.875 12.1875 11.4553 12.1875 10.9375C12.1875 10.4197 11.7678 10 11.25 10H5.625Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
