<template>
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 6.60021C0 2.95941 3.58517 0 7.99667 0C12.4082 0 15.9933 2.9594 16 6.59353C16 10.2343 12.4148 13.1937 8.00333 13.1937C7.09038 13.1937 6.19742 13.0668 5.34444 12.8196L1.0529 14.8237C1.00625 14.8438 0.959597 14.8571 0.91295 14.8571C0.826319 14.8571 0.739688 14.8237 0.673049 14.7569C0.573091 14.6567 0.546438 14.5031 0.613077 14.3762L2.21908 11.1629C0.78634 9.93369 0 8.31707 0 6.60021ZM8.96111 3.99998C8.96111 3.52659 8.57735 3.14284 8.10396 3.14284C7.63058 3.14284 7.24682 3.52659 7.24682 3.99998V5.71427H5.53253C5.05915 5.71427 4.67539 6.09802 4.67539 6.57141C4.67539 7.0448 5.05915 7.42855 5.53253 7.42855L7.24682 7.42855V9.14284C7.24682 9.61623 7.63057 9.99998 8.10396 9.99998C8.57735 9.99998 8.96111 9.61623 8.96111 9.14284V7.42855H10.6754C11.1488 7.42855 11.5325 7.0448 11.5325 6.57141C11.5325 6.09802 11.1488 5.71427 10.6754 5.71427H8.96111V3.99998Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
