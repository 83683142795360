import { AxiosError, AxiosRequestConfig } from 'axios';
import { z } from 'zod';
import { NotAuthorizedClient } from './not-authorized-client';
import { HttpConnection, RequestRetryLimitExceededException } from './http-connection';
import { AuthService } from '~/app-modules/auth/services/auth.service';
import { NotLoggedIn } from '~/app-modules/core/errors/not-logged-in';

export class AuthorizedClient extends NotAuthorizedClient {
  constructor(httpConnection: HttpConnection, private authService: AuthService) {
    super(httpConnection);
  }

  protected override async executeRequest<T>(
    options: AxiosRequestConfig,
    deserializer: z.Schema<T> = z.optional(z.any())
  ): Promise<T> {
    if (!this.authService.isLoggedIn) new NotLoggedIn();

    let tryCount = 1;

    while (tryCount <= 2) {
      try {
        const authToken = this.authService.Bearer;
        return await super.executeRequest(
          { ...options, headers: { Authorization: `${authToken}` } },
          deserializer
        );
      } catch (e) {
        if (this.isUnAuthorizedException(e)) {
          await this.authService.ensureTokensRefreshed();
          tryCount++;
          continue;
        }
        throw e;
      }
    }
    throw new RequestRetryLimitExceededException();
  }

  protected async executeNotAuthorizedRequest<T>(
    options: AxiosRequestConfig,
    deserializer: z.Schema<T> = z.optional(z.any())
  ): Promise<T> {
    return await super.executeRequest(options, deserializer);
  }

  private isUnAuthorizedException(e: unknown) {
    // eslint-disable-next-line eqeqeq
    return e instanceof AxiosError && e.response?.status == 401;
  }
}
