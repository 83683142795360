<template>
  <svg
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.7635 0.232135C8.07712 0.543346 8.07908 1.04987 7.76787 1.3635L4.59248 4.5635C4.44303 4.71411 4.23987 4.79918 4.02769 4.79999C3.81552 4.80081 3.61171 4.71731 3.4611 4.56785L0.236486 1.36785C-0.077128 1.05663 -0.0790687 0.5501 0.232151 0.236486C0.543371 -0.077128 1.0499 -0.0790687 1.36351 0.232151L4.02026 2.86862L6.63214 0.236503C6.94335 -0.0771204 7.44987 -0.0790761 7.7635 0.232135Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
