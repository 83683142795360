import { AxiosRequestConfig } from 'axios';
import { z } from 'zod';
import { HttpConnection } from './http-connection';

export class NotAuthorizedClient {
  constructor(protected connection: HttpConnection) {
    ensureInstanceOf(HttpConnection, { connection });
  }

  protected async executeRequest<T>(
    options: AxiosRequestConfig,
    deserializer: z.Schema<T> = z.optional(z.any())
  ): Promise<T> {
    const _options = {
      ...options,
      headers: {
        timeZoneOffset: new Date().getTimezoneOffset(),
        ...options.headers,
      },
    };
    const _result = await this.connection.executeRequest(_options);
    return deserializer.parse(_result);
  }
}
