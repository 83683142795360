<template>
  <v-btn
    v-bind="currentThemeAttrs"
    ref="btnRef"
    class="x-button"
    :class="{
      'only-icon': icon && (labelHidden || !label),
      [theme]: true,
    }"
    :data-test-id="dataTestId"
    @click="(e) => emit('click', e)"
  >
    <template v-if="label && !labelHidden">
      <div class="text-button">
        {{ label }}
      </div>
    </template>
    <v-tooltip
      activator="parent"
      :disabled="!tooltipText"
      location="bottom"
      content-class="button-tooltip"
      offset="4"
      :max-width="tooltipMaxWidth"
    >
      {{ tooltipText }}
    </v-tooltip>
  </v-btn>
</template>

<script setup lang="ts">
import { VBtn } from 'vuetify/lib/components/index.mjs';

defineOptions({
  name: 'XButton',
});

const emit = defineEmits<{
  (_eventName: 'click', _event: Event): void;
}>();

const btnRef = ref<VBtn>();

const active = ref<boolean>(btnRef.value?.$el.classList.contains('toggled'));

const observer = new MutationObserver((mutations) => {
  for (const m of mutations) {
    active.value = (m.target as HTMLElement).classList.contains('toggled');
  }
});

onMounted(() => {
  observer.observe(btnRef.value?.$el, {
    attributes: true,
    attributeOldValue: true,
    attributeFilter: ['class'],
  });

  active.value = btnRef.value?.$el.classList.contains('toggled');
});

onBeforeUnmount(() => {
  observer.disconnect();
});

const {
  theme = 'colored',
  color = 'blue',
  compact = false,
  label = '',
  labelHidden = false,
  minWidth = 'unset',
  maxWidth = 'unset',
  width = 'unset',
  block = false,
  iconPosition = 'left',
  icon,
  tooltipMaxWidth = 290,
  dataTestId = '',
} = defineProps<{
  theme?: 'outlined' | 'transparent' | 'colored';
  block?: boolean;
  tooltipText?: string;
  color?: string;
  compact?: boolean;
  label?: string;
  labelHidden?: boolean;
  icon?: string;
  iconPosition?: 'left' | 'right';
  minWidth?: string;
  maxWidth?: string;
  width?: string;
  tooltipMaxWidth?: number;
  dataTestId?: string;
}>();

const currentThemeAttrs = computed(() => {
  return Object.assign(
    {
      prependIcon: icon && iconPosition === 'left' ? icon : undefined,
      appendIcon: icon && iconPosition === 'right' ? icon : undefined,
      minWidth,
      maxWidth,
      width,
      block,
      density: compact ? 'comfortable' : 'default',
    },
    {
      outlined: {
        variant: 'outlined',
        rounded: 'lg',
        color: active.value ? 'blue' : 'beton64',
      },
      transparent: {
        variant: 'text',
        color: active.value ? 'blue' : 'beton64',
      },
      colored: {
        variant: 'flat',
        rounded: 'lg',
        color,
      },
    }[theme]
  );
});
</script>

<style lang="scss" scoped>
.v-btn {
  flex: 1;

  &.only-icon :deep(.v-btn__prepend) {
    margin-right: unset !important;
  }

  &.only-icon.v-btn--density-default {
    width: 36px !important;
  }

  &.only-icon.v-btn--density-comfortable {
    width: 28px !important;
  }

  &.outlined.v-btn--variant-outlined {
    border: thin solid color-mix(in srgb, currentColor 30%, transparent) !important;
  }
}

:deep(.button-tooltip) {
  background-color: rgb(var(--v-theme-beton));
  border-radius: 6px;
  padding: 6px 12px;

  color: white;

  @include get-text-styles(caption);

  &.error {
    background-color: rgb(var(--v-theme-red));
  }
}
</style>

<style lang="scss">
.v-btn {
  text-transform: unset !important;

  .v-btn__prepend {
    margin-right: 4px;
    margin-inline-start: unset !important;
  }

  .v-btn__append {
    margin-left: 4px;
  }
}
</style>
