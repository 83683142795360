import { AuthService } from '~/app-modules/auth/services/auth.service';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const {
    meta: { requiresAuth = true },
    name,
    path,
  } = to;

  const authService = useIOC(AuthService);
  const userLoggedIn = authService.isLoggedIn;

  // перехват редиректа от sso (на уровне мидлвары работает без лоадеров и очень быстро)
  if (path === '/auth-redirect') {
    try {
      const { code, backUrl } = useRoute().query as { code: string; backUrl?: string };
      await authService.authorizeBySsoCode(code);

      return backUrl || '/';
    } catch (error) {
      return '/auth/';
    }
  }

  // Условия прервать навигацию и изменить роут назначения
  if (name === 'auth' && userLoggedIn) {
    return from.path === '/auth/' ? '/' : abortNavigation();
  }

  // Условия продолжения навигации
  if (name === 'auth' && !userLoggedIn) {
    return;
  } else if (!requiresAuth || userLoggedIn) {
    return;
  }

  // роут защищен и нет авторизации
  return `/auth/?from=${from.path}`;
});

declare module '#app' {
  interface PageMeta {
    /**
     * Установлена по умолчанию как true
     */
    requiresAuth?: boolean;
  }
}

declare module 'vue-router' {
  interface RouteMeta {
    /**
     * Установлена по умолчанию как true
     */
    requiresAuth?: boolean;
  }
}
