import { default as indexnn8eIw9js0Meta } from "/builds/coingroup/fishcoin/fishplan-client/pages/auth/index.vue?macro=true";
import { default as _91fishType_93j09VTcblgDMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/farm/[fishType].vue?macro=true";
import { default as _91areaId_93OsLQ6hE8UgMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/farm/fish-area/[areaId].vue?macro=true";
import { default as _91tankId_93K4d0xrjqT7Meta } from "/builds/coingroup/fishcoin/fishplan-client/pages/farm/fish-tank/[tankId].vue?macro=true";
import { default as farmfq40OzEGO8Meta } from "/builds/coingroup/fishcoin/fishplan-client/pages/farm.vue?macro=true";
import { default as update2HwIDOuBjoMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/feeds/[feedId]/feeding-charts/[feedingChartId]/update.vue?macro=true";
import { default as createeqBb5pQFl2Meta } from "/builds/coingroup/fishcoin/fishplan-client/pages/feeds/[feedId]/feeding-charts/create.vue?macro=true";
import { default as indexPZAGO2Cmq3Meta } from "/builds/coingroup/fishcoin/fishplan-client/pages/feeds/index.vue?macro=true";
import { default as updateA4arJjaoXYMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/fish-area/[id]/update.vue?macro=true";
import { default as create4xk7DftuXnMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/fish-area/[id]/water-quality/create.vue?macro=true";
import { default as createLlTHen5HDDMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/fish-area/create.vue?macro=true";
import { default as createullaVBPMqqMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/fish-tank/[tankId]/water-quality/create.vue?macro=true";
import { default as indexS3M2rs9mxVMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/index.vue?macro=true";
import { default as create7ZYau3mghOMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/operation-[operationType]/fish-area-[fishAreaId]/create.vue?macro=true";
import { default as updatevcaxOuVFaaMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/operation-[operationType]/fish-tank-[fishTankId]/[operationId]/update.vue?macro=true";
import { default as createYsVqiofB3dMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/operation-[operationType]/fish-tank-[fishTankId]/create.vue?macro=true";
import { default as story_45book_45litexZMpkwFcXWMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/story-book-lite.vue?macro=true";
import { default as updatelh5syYabT0Meta } from "/builds/coingroup/fishcoin/fishplan-client/pages/warehouse-transfer-[transferType]/[id]/update.vue?macro=true";
import { default as createiXYObNMBHBMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/warehouse-transfer-[transferType]/create.vue?macro=true";
import { default as updateY42XH5KkppMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/warehouse/[id]/update.vue?macro=true";
import { default as createCYgJxsqmzNMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/warehouse/create.vue?macro=true";
import { default as catalogxJPaACVNmDMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/warehouses/catalog.vue?macro=true";
import { default as indexsX65er7SmkMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/warehouses/index.vue?macro=true";
import { default as productsii92oI9mXJMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/warehouses/products.vue?macro=true";
import { default as transfersG2E9CMpmeRMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/warehouses/transfers.vue?macro=true";
import { default as indexEXi27MzKgaMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/warehouses/warehouse/[warehouseId]/index.vue?macro=true";
import { default as productsJkrNbCwb8pMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/warehouses/warehouse/[warehouseId]/products.vue?macro=true";
import { default as transfersgL6dvrZwBbMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/warehouses/warehouse/[warehouseId]/transfers.vue?macro=true";
import { default as warehousesDdvnSC6W1wMeta } from "/builds/coingroup/fishcoin/fishplan-client/pages/warehouses.vue?macro=true";
export default [
  {
    name: indexnn8eIw9js0Meta?.name ?? "auth",
    path: indexnn8eIw9js0Meta?.path ?? "/auth",
    meta: indexnn8eIw9js0Meta || {},
    alias: indexnn8eIw9js0Meta?.alias || [],
    redirect: indexnn8eIw9js0Meta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: farmfq40OzEGO8Meta?.name ?? "farm",
    path: farmfq40OzEGO8Meta?.path ?? "/farm",
    children: [
  {
    name: _91fishType_93j09VTcblgDMeta?.name ?? "farm-fishType",
    path: _91fishType_93j09VTcblgDMeta?.path ?? ":fishType()",
    meta: _91fishType_93j09VTcblgDMeta || {},
    alias: _91fishType_93j09VTcblgDMeta?.alias || [],
    redirect: _91fishType_93j09VTcblgDMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/farm/[fishType].vue").then(m => m.default || m)
  },
  {
    name: _91areaId_93OsLQ6hE8UgMeta?.name ?? "farm-fish-area-areaId",
    path: _91areaId_93OsLQ6hE8UgMeta?.path ?? "fish-area/:areaId()",
    meta: _91areaId_93OsLQ6hE8UgMeta || {},
    alias: _91areaId_93OsLQ6hE8UgMeta?.alias || [],
    redirect: _91areaId_93OsLQ6hE8UgMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/farm/fish-area/[areaId].vue").then(m => m.default || m)
  },
  {
    name: _91tankId_93K4d0xrjqT7Meta?.name ?? "farm-fish-tank-tankId",
    path: _91tankId_93K4d0xrjqT7Meta?.path ?? "fish-tank/:tankId()",
    meta: _91tankId_93K4d0xrjqT7Meta || {},
    alias: _91tankId_93K4d0xrjqT7Meta?.alias || [],
    redirect: _91tankId_93K4d0xrjqT7Meta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/farm/fish-tank/[tankId].vue").then(m => m.default || m)
  }
],
    meta: farmfq40OzEGO8Meta || {},
    alias: farmfq40OzEGO8Meta?.alias || [],
    redirect: farmfq40OzEGO8Meta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/farm.vue").then(m => m.default || m)
  },
  {
    name: update2HwIDOuBjoMeta?.name ?? "feeds-feedId-feeding-charts-feedingChartId-update",
    path: update2HwIDOuBjoMeta?.path ?? "/feeds/:feedId()/feeding-charts/:feedingChartId()/update",
    meta: update2HwIDOuBjoMeta || {},
    alias: update2HwIDOuBjoMeta?.alias || [],
    redirect: update2HwIDOuBjoMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/feeds/[feedId]/feeding-charts/[feedingChartId]/update.vue").then(m => m.default || m)
  },
  {
    name: createeqBb5pQFl2Meta?.name ?? "feeds-feedId-feeding-charts-create",
    path: createeqBb5pQFl2Meta?.path ?? "/feeds/:feedId()/feeding-charts/create",
    meta: createeqBb5pQFl2Meta || {},
    alias: createeqBb5pQFl2Meta?.alias || [],
    redirect: createeqBb5pQFl2Meta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/feeds/[feedId]/feeding-charts/create.vue").then(m => m.default || m)
  },
  {
    name: indexPZAGO2Cmq3Meta?.name ?? "feeds",
    path: indexPZAGO2Cmq3Meta?.path ?? "/feeds",
    meta: indexPZAGO2Cmq3Meta || {},
    alias: indexPZAGO2Cmq3Meta?.alias || [],
    redirect: indexPZAGO2Cmq3Meta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/feeds/index.vue").then(m => m.default || m)
  },
  {
    name: updateA4arJjaoXYMeta?.name ?? "fish-area-id-update",
    path: updateA4arJjaoXYMeta?.path ?? "/fish-area/:id()/update",
    meta: updateA4arJjaoXYMeta || {},
    alias: updateA4arJjaoXYMeta?.alias || [],
    redirect: updateA4arJjaoXYMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/fish-area/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: create4xk7DftuXnMeta?.name ?? "fish-area-id-water-quality-create",
    path: create4xk7DftuXnMeta?.path ?? "/fish-area/:id()/water-quality/create",
    meta: create4xk7DftuXnMeta || {},
    alias: create4xk7DftuXnMeta?.alias || [],
    redirect: create4xk7DftuXnMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/fish-area/[id]/water-quality/create.vue").then(m => m.default || m)
  },
  {
    name: createLlTHen5HDDMeta?.name ?? "fish-area-create",
    path: createLlTHen5HDDMeta?.path ?? "/fish-area/create",
    meta: createLlTHen5HDDMeta || {},
    alias: createLlTHen5HDDMeta?.alias || [],
    redirect: createLlTHen5HDDMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/fish-area/create.vue").then(m => m.default || m)
  },
  {
    name: createullaVBPMqqMeta?.name ?? "fish-tank-tankId-water-quality-create",
    path: createullaVBPMqqMeta?.path ?? "/fish-tank/:tankId()/water-quality/create",
    meta: createullaVBPMqqMeta || {},
    alias: createullaVBPMqqMeta?.alias || [],
    redirect: createullaVBPMqqMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/fish-tank/[tankId]/water-quality/create.vue").then(m => m.default || m)
  },
  {
    name: indexS3M2rs9mxVMeta?.name ?? "index",
    path: indexS3M2rs9mxVMeta?.path ?? "/",
    meta: indexS3M2rs9mxVMeta || {},
    alias: indexS3M2rs9mxVMeta?.alias || [],
    redirect: indexS3M2rs9mxVMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: create7ZYau3mghOMeta?.name ?? "operation-operationType-fish-area-fishAreaId-create",
    path: create7ZYau3mghOMeta?.path ?? "/operation-:operationType()/fish-area-:fishAreaId()/create",
    meta: create7ZYau3mghOMeta || {},
    alias: create7ZYau3mghOMeta?.alias || [],
    redirect: create7ZYau3mghOMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/operation-[operationType]/fish-area-[fishAreaId]/create.vue").then(m => m.default || m)
  },
  {
    name: updatevcaxOuVFaaMeta?.name ?? "operation-operationType-fish-tank-fishTankId-operationId-update",
    path: updatevcaxOuVFaaMeta?.path ?? "/operation-:operationType()/fish-tank-:fishTankId()/:operationId()/update",
    meta: updatevcaxOuVFaaMeta || {},
    alias: updatevcaxOuVFaaMeta?.alias || [],
    redirect: updatevcaxOuVFaaMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/operation-[operationType]/fish-tank-[fishTankId]/[operationId]/update.vue").then(m => m.default || m)
  },
  {
    name: createYsVqiofB3dMeta?.name ?? "operation-operationType-fish-tank-fishTankId-create",
    path: createYsVqiofB3dMeta?.path ?? "/operation-:operationType()/fish-tank-:fishTankId()/create",
    meta: createYsVqiofB3dMeta || {},
    alias: createYsVqiofB3dMeta?.alias || [],
    redirect: createYsVqiofB3dMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/operation-[operationType]/fish-tank-[fishTankId]/create.vue").then(m => m.default || m)
  },
  {
    name: story_45book_45litexZMpkwFcXWMeta?.name ?? "story-book-lite",
    path: story_45book_45litexZMpkwFcXWMeta?.path ?? "/story-book-lite",
    meta: story_45book_45litexZMpkwFcXWMeta || {},
    alias: story_45book_45litexZMpkwFcXWMeta?.alias || [],
    redirect: story_45book_45litexZMpkwFcXWMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/story-book-lite.vue").then(m => m.default || m)
  },
  {
    name: updatelh5syYabT0Meta?.name ?? "warehouse-transfer-transferType-id-update",
    path: updatelh5syYabT0Meta?.path ?? "/warehouse-transfer-:transferType()/:id()/update",
    meta: updatelh5syYabT0Meta || {},
    alias: updatelh5syYabT0Meta?.alias || [],
    redirect: updatelh5syYabT0Meta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/warehouse-transfer-[transferType]/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createiXYObNMBHBMeta?.name ?? "warehouse-transfer-transferType-create",
    path: createiXYObNMBHBMeta?.path ?? "/warehouse-transfer-:transferType()/create",
    meta: createiXYObNMBHBMeta || {},
    alias: createiXYObNMBHBMeta?.alias || [],
    redirect: createiXYObNMBHBMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/warehouse-transfer-[transferType]/create.vue").then(m => m.default || m)
  },
  {
    name: updateY42XH5KkppMeta?.name ?? "warehouse-id-update",
    path: updateY42XH5KkppMeta?.path ?? "/warehouse/:id()/update",
    meta: updateY42XH5KkppMeta || {},
    alias: updateY42XH5KkppMeta?.alias || [],
    redirect: updateY42XH5KkppMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/warehouse/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createCYgJxsqmzNMeta?.name ?? "warehouse-create",
    path: createCYgJxsqmzNMeta?.path ?? "/warehouse/create",
    meta: createCYgJxsqmzNMeta || {},
    alias: createCYgJxsqmzNMeta?.alias || [],
    redirect: createCYgJxsqmzNMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/warehouse/create.vue").then(m => m.default || m)
  },
  {
    path: warehousesDdvnSC6W1wMeta?.path ?? "/warehouses",
    children: [
  {
    name: catalogxJPaACVNmDMeta?.name ?? "warehouses-catalog",
    path: catalogxJPaACVNmDMeta?.path ?? "catalog",
    meta: catalogxJPaACVNmDMeta || {},
    alias: catalogxJPaACVNmDMeta?.alias || [],
    redirect: catalogxJPaACVNmDMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/warehouses/catalog.vue").then(m => m.default || m)
  },
  {
    name: indexsX65er7SmkMeta?.name ?? "warehouses",
    path: indexsX65er7SmkMeta?.path ?? "",
    meta: indexsX65er7SmkMeta || {},
    alias: indexsX65er7SmkMeta?.alias || [],
    redirect: indexsX65er7SmkMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/warehouses/index.vue").then(m => m.default || m)
  },
  {
    name: productsii92oI9mXJMeta?.name ?? "warehouses-products",
    path: productsii92oI9mXJMeta?.path ?? "products",
    meta: productsii92oI9mXJMeta || {},
    alias: productsii92oI9mXJMeta?.alias || [],
    redirect: productsii92oI9mXJMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/warehouses/products.vue").then(m => m.default || m)
  },
  {
    name: transfersG2E9CMpmeRMeta?.name ?? "warehouses-transfers",
    path: transfersG2E9CMpmeRMeta?.path ?? "transfers",
    meta: transfersG2E9CMpmeRMeta || {},
    alias: transfersG2E9CMpmeRMeta?.alias || [],
    redirect: transfersG2E9CMpmeRMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/warehouses/transfers.vue").then(m => m.default || m)
  },
  {
    name: indexEXi27MzKgaMeta?.name ?? "warehouses-warehouse-warehouseId",
    path: indexEXi27MzKgaMeta?.path ?? "warehouse/:warehouseId()",
    meta: indexEXi27MzKgaMeta || {},
    alias: indexEXi27MzKgaMeta?.alias || [],
    redirect: indexEXi27MzKgaMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/warehouses/warehouse/[warehouseId]/index.vue").then(m => m.default || m)
  },
  {
    name: productsJkrNbCwb8pMeta?.name ?? "warehouses-warehouse-warehouseId-products",
    path: productsJkrNbCwb8pMeta?.path ?? "warehouse/:warehouseId()/products",
    meta: productsJkrNbCwb8pMeta || {},
    alias: productsJkrNbCwb8pMeta?.alias || [],
    redirect: productsJkrNbCwb8pMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/warehouses/warehouse/[warehouseId]/products.vue").then(m => m.default || m)
  },
  {
    name: transfersgL6dvrZwBbMeta?.name ?? "warehouses-warehouse-warehouseId-transfers",
    path: transfersgL6dvrZwBbMeta?.path ?? "warehouse/:warehouseId()/transfers",
    meta: transfersgL6dvrZwBbMeta || {},
    alias: transfersgL6dvrZwBbMeta?.alias || [],
    redirect: transfersgL6dvrZwBbMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/warehouses/warehouse/[warehouseId]/transfers.vue").then(m => m.default || m)
  }
],
    name: warehousesDdvnSC6W1wMeta?.name ?? undefined,
    meta: warehousesDdvnSC6W1wMeta || {},
    alias: warehousesDdvnSC6W1wMeta?.alias || [],
    redirect: warehousesDdvnSC6W1wMeta?.redirect || undefined,
    component: () => import("/builds/coingroup/fishcoin/fishplan-client/pages/warehouses.vue").then(m => m.default || m)
  }
]