<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="path-1-inside-1_2532_18542"
      fill="white"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 18.7499C14.8325 18.7499 18.75 14.8324 18.75 9.99993C18.75 5.16748 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16748 1.25 9.99993C1.25 14.8324 5.16751 18.7499 10 18.7499ZM11.25 6.24996C11.25 6.94031 10.6904 7.49995 10 7.49995C9.30964 7.49995 8.75 6.94031 8.75 6.24996C8.75 5.55961 9.30964 4.99997 10 4.99997C10.6904 4.99997 11.25 5.55961 11.25 6.24996ZM10 8.74628C10.5178 8.74628 10.9375 9.16601 10.9375 9.68377V14.0722C10.9375 14.5899 10.5178 15.0097 10 15.0097C9.48223 15.0097 9.0625 14.5899 9.0625 14.0722V9.68377C9.0625 9.16601 9.48223 8.74628 10 8.74628Z"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 18.7499C14.8325 18.7499 18.75 14.8324 18.75 9.99993C18.75 5.16748 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16748 1.25 9.99993C1.25 14.8324 5.16751 18.7499 10 18.7499ZM11.25 6.24996C11.25 6.94031 10.6904 7.49995 10 7.49995C9.30964 7.49995 8.75 6.94031 8.75 6.24996C8.75 5.55961 9.30964 4.99997 10 4.99997C10.6904 4.99997 11.25 5.55961 11.25 6.24996ZM10 8.74628C10.5178 8.74628 10.9375 9.16601 10.9375 9.68377V14.0722C10.9375 14.5899 10.5178 15.0097 10 15.0097C9.48223 15.0097 9.0625 14.5899 9.0625 14.0722V9.68377C9.0625 9.16601 9.48223 8.74628 10 8.74628Z"
      fill="currentColor"
    />
    <path
      d="M17.25 9.99993C17.25 14.004 14.0041 17.2499 10 17.2499V20.2499C15.6609 20.2499 20.25 15.6608 20.25 9.99993H17.25ZM10 2.75C14.0041 2.75 17.25 5.99592 17.25 9.99993H20.25C20.25 4.33904 15.6609 -0.25 10 -0.25V2.75ZM2.75 9.99993C2.75 5.99592 5.99592 2.75 10 2.75V-0.25C4.33909 -0.25 -0.25 4.33904 -0.25 9.99993H2.75ZM10 17.2499C5.99592 17.2499 2.75 14.004 2.75 9.99993H-0.25C-0.25 15.6608 4.33909 20.2499 10 20.2499V17.2499ZM10 8.99995C11.5188 8.99995 12.75 7.76875 12.75 6.24996H9.75C9.75 6.11188 9.86194 5.99995 10 5.99995V8.99995ZM7.25 6.24996C7.25 7.76875 8.48123 8.99995 10 8.99995V5.99995C10.1381 5.99995 10.25 6.11188 10.25 6.24996H7.25ZM10 3.49997C8.48123 3.49997 7.25 4.73117 7.25 6.24996H10.25C10.25 6.38805 10.1381 6.49997 10 6.49997V3.49997ZM12.75 6.24996C12.75 4.73117 11.5188 3.49997 10 3.49997V6.49997C9.86194 6.49997 9.75 6.38805 9.75 6.24996H12.75ZM12.4375 9.68377C12.4375 8.33757 11.3462 7.24628 10 7.24628V10.2463C9.68935 10.2463 9.4375 9.99445 9.4375 9.68377H12.4375ZM12.4375 14.0722V9.68377H9.4375V14.0722H12.4375ZM10 16.5097C11.3462 16.5097 12.4375 15.4184 12.4375 14.0722H9.4375C9.4375 13.7615 9.68935 13.5097 10 13.5097V16.5097ZM7.5625 14.0722C7.5625 15.4184 8.65382 16.5097 10 16.5097V13.5097C10.3106 13.5097 10.5625 13.7615 10.5625 14.0722H7.5625ZM7.5625 9.68377V14.0722H10.5625V9.68377H7.5625ZM10 7.24628C8.65382 7.24628 7.5625 8.33757 7.5625 9.68377H10.5625C10.5625 9.99445 10.3106 10.2463 10 10.2463V7.24628Z"
      fill="currentColor"
      mask="url(#path-1-inside-1_2532_18542)"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
