import { z } from 'zod';

export const UserDto = z.object({
  userId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  middleName: z.string().nullable(),
  organizationId: z.string().optional(),
  organizationName: z.string().optional(),
});

export type TUserDto = z.infer<typeof UserDto>;
