<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0947 2.21917C10.526 1.87416 11.1553 1.94408 11.5003 2.37534L13.4957 4.86964C13.6215 5.02435 13.7019 5.21737 13.7168 5.42849C13.7205 5.47902 13.7202 5.52977 13.7162 5.58027C13.7006 5.77676 13.6282 5.95725 13.5154 6.10533C13.5104 6.11185 13.5054 6.11831 13.5003 6.12473L11.5003 8.62473C11.1553 9.05599 10.526 9.12592 10.0947 8.78091C9.66344 8.4359 9.59352 7.8066 9.93853 7.37534L10.6388 6.5H6.71936C6.16708 6.5 5.71936 6.05229 5.71936 5.5C5.71936 4.94772 6.16708 4.5 6.71936 4.5H10.6387L9.93853 3.62473C9.59352 3.19347 9.66344 2.56418 10.0947 2.21917ZM6.62469 14.7809C6.19343 15.1259 5.56414 15.056 5.21913 14.6247L3.21913 12.1247C2.92696 11.7595 2.92696 11.2406 3.21913 10.8753L5.21913 8.37534C5.56414 7.94408 6.19343 7.87416 6.6247 8.21917C7.05596 8.56418 7.12588 9.19347 6.78087 9.62473L6.08064 10.5L10.1336 10.5C10.6859 10.5 11.1336 10.9477 11.1336 11.5C11.1336 12.0523 10.6859 12.5 10.1336 12.5L6.08061 12.5L6.78087 13.3753C7.12588 13.8066 7.05596 14.4359 6.62469 14.7809Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
