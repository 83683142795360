<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.0625 5C4.0625 3.79188 5.04188 2.8125 6.25 2.8125H10.1072C10.6874 2.8125 11.2438 3.04297 11.654 3.4532L15.2968 7.09597C15.707 7.50621 15.9375 8.06261 15.9375 8.64277V16.25C15.9375 17.4581 14.9581 18.4375 13.75 18.4375H6.25C5.04188 18.4375 4.0625 17.4581 4.0625 16.25V5ZM14.4665 8.21653C14.6634 8.41339 14.524 8.75 14.2456 8.75H10.625C10.2798 8.75 10 8.47018 10 8.125V4.50444C10 4.22603 10.3366 4.08661 10.5335 4.28347L14.4665 8.21653Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
