export enum UiDictionaryType {
  FishType = 'fish_type',
  OperationReason = 'operation_reason',
  OperationType = 'operation_type',
  FeedingRatioIntention = 'feeding_ratio_intention',
  FishFeedType = 'fish_feed_type',
  TransferType = 'transfer_type',
  ProductCategory = 'product_category',
  ProductTransferReason = 'product_transfer_reason',
}
