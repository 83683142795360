import { singleton } from 'tsyringe';
import { AuthorizedClient } from '~/app-modules/core/clients/authorized-client';
import { HttpConnection } from '~/app-modules/core/clients/http-connection';
import { AuthService } from '~/app-modules/auth/services/auth.service';
import { UserDto } from '~/app-modules/user/clients/dto/user.dto';
import {
  EmployeeForDescriptionDto,
  EmployeeForDescriptionDtoSchema,
} from '~/app-modules/user/clients/dto/employee-for-description.dto';

@singleton()
export class UserClient extends AuthorizedClient {
  constructor(http: HttpConnection, authService: AuthService) {
    super(http, authService);
  }

  /**
   *  Получение информации о текущем юзере
   */
  async getMe() {
    return await this.executeRequest(
      {
        method: 'get',
        url: '/v1/auth/employee/current',
      },
      UserDto
    );
  }

  async findEmployeesForDescription(employeeIds: string[]): Promise<EmployeeForDescriptionDto[]> {
    return await this.executeRequest(
      {
        method: 'post',
        url: '/v1/employee/find-for-description',
        data: {
          employeeIds,
        },
      },
      EmployeeForDescriptionDtoSchema.array()
    );
  }
}
