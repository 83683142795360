<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="11"
    fill="none"
    viewBox="0 0 14 11"
  >
    <path
      stroke="none"
      d="M12.966 3.867c.194.476-.218.934-.732.934h-.122c-.358 0-.64-.278-.815-.59a1.502 1.502 0 0 0-.371-.445 1.633 1.633 0 0 0-.524-.282 2.002 2.002 0 0 0-.636-.097c-.414 0-.775.103-1.082.308-.308.203-.546.5-.715.89-.17.389-.254.86-.254 1.415 0 .57.084 1.05.254 1.438.172.388.411.68.718.878.308.198.663.297 1.067.297.226 0 .436-.03.629-.09.195-.06.368-.147.52-.261.15-.117.275-.26.374-.426.184-.302.469-.57.823-.568l.12.001c.521.002.93.47.709.942a3.194 3.194 0 0 1-.769 1.035 3.329 3.329 0 0 1-1.055.633c-.406.154-.865.23-1.378.23-.714 0-1.352-.161-1.915-.484a3.438 3.438 0 0 1-1.328-1.402C6.161 7.61 6 6.87 6 6c0-.872.164-1.615.492-2.227a3.43 3.43 0 0 1 1.336-1.398c.563-.323 1.195-.484 1.899-.484.463 0 .893.065 1.289.195.398.13.75.32 1.058.57.308.248.558.551.75.91.053.097.1.197.142.3Z"
    />
    <path
      fill="none"
      stroke-width="1.5"
      d="M2.5 4.25a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
  stroke: currentColor;
}
</style>
