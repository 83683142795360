import { singleton } from 'tsyringe';
import axios, { Axios, AxiosRequestConfig, CancelToken, CancelTokenSource } from 'axios';

export type { CancelToken, CancelTokenSource };

export const createCancelTokenSource = () => {
  return axios!.CancelToken.source();
};

export const isRequestCancel = axios!.isCancel;

singleton();
export class HttpConnection {
  constructor() {
    this.http = axios.create({
      baseURL: useRuntimeConfig().public.app.apiUrl,
    });
  }

  private http: Axios;

  getUri() {
    return this.http.getUri();
  }

  async executeRequest(options: AxiosRequestConfig): Promise<{ [key: string]: any }> {
    const _result = await this.http.request(options);
    return _result.data;
  }
}

export class RequestRetryLimitExceededException extends Error {
  message = 'Request Retry limit was exceeded';
}
