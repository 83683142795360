import { AuthorizedClient } from '~/app-modules/core/clients/authorized-client';
import { HttpConnection } from '~/app-modules/core/clients/http-connection';
import { AuthService } from '~/app-modules/auth/services/auth.service';
import { UiDictionariesDtoSchema } from '~/app-modules/core/clients/dto/ui-dictionaries.dto';

@singleton()
export class UiDictionariesClient extends AuthorizedClient {
  constructor(http: HttpConnection, authService: AuthService) {
    super(http, authService);
  }

  async getUiDictionaries() {
    return await this.executeRequest(
      {
        method: 'get',
        url: '/v1/ui-dictionaries',
      },
      UiDictionariesDtoSchema
    );
  }
}
