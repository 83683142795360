<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="13"
    fill="none"
    viewBox="0 0 9 13"
  >
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M8.35 4.11c0 .786-.135 1.467-.405 2.042A4.077 4.077 0 0 0 6.792 6c-.253 0-.493.02-.72.06.12-.15.223-.323.308-.52.17-.389.254-.866.254-1.43 0-.566-.084-1.042-.254-1.43-.166-.388-.402-.683-.707-.883-.304-.2-.661-.3-1.07-.3-.409 0-.766.1-1.07.3-.305.2-.542.495-.711.883-.167.388-.25.864-.25 1.43 0 .564.083 1.041.25 1.43.169.387.406.681.71.882.305.2.662.3 1.071.3h.027c-.156.14-.29.297-.404.473a2.2 2.2 0 0 0-.248.524.561.561 0 0 0 .048.462 3.645 3.645 0 0 1-1.337-.447 3.448 3.448 0 0 1-1.34-1.402C1.021 5.72.857 4.979.857 4.109c0-.872.164-1.614.492-2.226A3.422 3.422 0 0 1 2.69.484C3.254.161 3.892 0 4.603 0c.706 0 1.341.161 1.906.484.568.32 1.016.787 1.344 1.399.33.612.496 1.354.496 2.226Z"
      clip-rule="evenodd"
    />
    <path
      stroke="none"
      stroke-width=".15"
      d="M6.952 9.153c-.098.112-.22.24-.367.38l-1.566 1.45a.591.591 0 0 0 .402 1.025h2.533a.496.496 0 0 0 0-.99H6.48l.612-.6c.335-.306.597-.568.783-.789.187-.222.32-.428.395-.617a1.49 1.49 0 0 0 .115-.572c0-.294-.076-.557-.229-.788a1.52 1.52 0 0 0-.636-.534 2.162 2.162 0 0 0-.942-.193c-.349 0-.66.066-.932.2a1.52 1.52 0 0 0-.637.572 1.47 1.47 0 0 0-.16.366.421.421 0 0 0 .1.41.542.542 0 0 0 .396.168h.073a.52.52 0 0 0 .348-.144c.094-.083.168-.192.224-.297a.548.548 0 0 1 .227-.229.73.73 0 0 1 .353-.08.76.76 0 0 1 .35.077.567.567 0 0 1 .322.541.756.756 0 0 1-.072.327 1.389 1.389 0 0 1-.218.317Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
  stroke: currentColor;
}
</style>
