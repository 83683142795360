<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.28518 1C3.70006 1 3.15366 1.29243 2.82909 1.77927L1.29391 4.08205C1.10227 4.36952 1 4.70728 1 5.05277V11.75C1 12.7165 1.7835 13.5 2.75 13.5H6.75C7.16421 13.5 7.5 13.1642 7.5 12.75C7.5 12.3358 7.16421 12 6.75 12H2.75C2.61193 12 2.5 11.8881 2.5 11.75V5.5H12V7C12 7.41421 12.3358 7.75 12.75 7.75C13.1642 7.75 13.5 7.41421 13.5 7V5.05277C13.5 4.70728 13.3977 4.36952 13.2061 4.08205L11.6709 1.77927C11.3463 1.29243 10.7999 1 10.2148 1H4.28518ZM4.07717 2.61132C4.12354 2.54177 4.20159 2.5 4.28518 2.5H10.2148C10.2984 2.5 10.3765 2.54177 10.4228 2.61132L11.3486 4H3.15139L4.07717 2.61132ZM12.75 9.75C12.75 9.33579 12.4142 9 12 9C11.5858 9 11.25 9.33579 11.25 9.75V11.25H9.75C9.33579 11.25 9 11.5858 9 12C9 12.4142 9.33579 12.75 9.75 12.75H11.25V14.25C11.25 14.6642 11.5858 15 12 15C12.4142 15 12.75 14.6642 12.75 14.25V12.75H14.25C14.6642 12.75 15 12.4142 15 12C15 11.5858 14.6642 11.25 14.25 11.25H12.75V9.75Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
