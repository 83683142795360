<template>
  <v-card
    v-bind="currentThemeAttrs"
    class="x-card"
  >
    <!--  отслеживание Hover'а внутри компонента т.к. на v-card вычисляемые атрибуты   -->
    <!--  это влечет наложение падингов только через contentClass  -->
    <v-hover v-slot="{ isHovering, props }">
      <slot
        name="content"
        :content-color="contentColor"
        :content-class="contentClass"
        :content-props="props"
        :is-hovering="isHovering || $vuetify.display.xs"
      >
        <div
          v-bind="props"
          :class="`text-${contentColor} ${contentClass}`"
        >
          <slot :is-hovering="isHovering || $vuetify.display.xs" />
        </div>
      </slot>
    </v-hover>
  </v-card>
</template>

<script setup lang="ts">
import { VCard } from 'vuetify/lib/components/index.mjs';

defineOptions({
  name: 'XCard',
});

const {
  theme = 'card',
  color,
  contentColor = 'beton64',
  contentClass = '',
  minWidth = 'unset',
  maxWidth = 'unset',
  width = 'unset',
} = defineProps<{
  theme?: 'card' | 'box';
  color?: string;
  contentColor?: string;
  contentClass?: string;
  minWidth?: string;
  maxWidth?: string;
  width?: string;
}>();

const currentThemeAttrs: ComputedRef<Partial<typeof VCard>> = computed(() => {
  return Object.assign(
    {
      minWidth,
      maxWidth,
      width,
    },
    {
      card: {
        variant: 'flat',
        rounded: 'xl',
        elevation: 12,
        border: true,
        color: color || 'white',
        style: {
          backgroundColor: color || 'white',
        },
      },
      box: {
        variant: 'flat',
        rounded: 'xl',
        elevation: 0,
        color: color || 'beton4',
      },
    }[theme]
  );
});
</script>
