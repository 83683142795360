<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.9375 5C5.9375 4.82741 6.07741 4.6875 6.25 4.6875H9.7C9.86569 4.6875 10 4.82181 10 4.9875V8.125C10 8.47018 10.2798 8.75 10.625 8.75H13.7625C13.9282 8.75 14.0625 8.88431 14.0625 9.05V16.25C14.0625 16.4226 13.9226 16.5625 13.75 16.5625H6.25C6.07741 16.5625 5.9375 16.4226 5.9375 16.25V5ZM6.25 2.8125C5.04188 2.8125 4.0625 3.79188 4.0625 5V16.25C4.0625 17.4581 5.04188 18.4375 6.25 18.4375H13.75C14.9581 18.4375 15.9375 17.4581 15.9375 16.25V8.64277C15.9375 8.06261 15.707 7.50621 15.2968 7.09597L11.654 3.4532C11.2438 3.04297 10.6874 2.8125 10.1072 2.8125H6.25Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
