import 'vuetify/_settings.scss';
import { createVuetify } from 'vuetify/lib/framework.mjs';
import Color from 'color';
import { generateThemeColorList } from '~/app-modules/core/utils/helpers';

import ArrowDown from '~/app-modules/core/components/icons/arrow-down.vue';
import ArrowLeft from '~/app-modules/core/components/icons/arrow-left.vue';
import AutoFix from '~/app-modules/core/components/icons/auto-fix.vue';
import Box from '~/app-modules/core/components/icons/box.vue';
import CalendarEdit from '~/app-modules/core/components/icons/calendar-edit.vue';
import Check from '~/app-modules/core/components/icons/check.vue';
import NoIcon from '~/app-modules/core/components/icons/no-icon.vue';
import Close from '~/app-modules/core/components/icons/close.vue';
import Copy from '~/app-modules/core/components/icons/copy.vue';
import Cylinder from '~/app-modules/core/components/icons/cylinder.vue';
import Delete from '~/app-modules/core/components/icons/delete.vue';
import EditPen from '~/app-modules/core/components/icons/edit-pen.vue';
import Error from '~/app-modules/core/components/icons/error.vue';
import LabelAmmonium from '~/app-modules/core/components/icons/label-ammonium.vue';
import LabelHydrogen from '~/app-modules/core/components/icons/label-hydrogen.vue';
import LabelNitrate from '~/app-modules/core/components/icons/label-nitrate.vue';
import LabelNitrite from '~/app-modules/core/components/icons/label-nitrite.vue';
import LabelNitrogen from '~/app-modules/core/components/icons/label-nitrogen.vue';
import LabelOxygen from '~/app-modules/core/components/icons/label-oxygen.vue';
import LabelTemperature from '~/app-modules/core/components/icons/label-temperature.vue';
import Logout from '~/app-modules/core/components/icons/logout.vue';
import Math from '~/app-modules/core/components/icons/math.vue';
import Menu from '~/app-modules/core/components/icons/menu.vue';
import Plus from '~/app-modules/core/components/icons/plus.vue';
import Minus from '~/app-modules/core/components/icons/minus.vue';
import Moving from '~/app-modules/core/components/icons/moving.vue';
import Warning from '~/app-modules/core/components/icons/warning.vue';
import Repeat from '~/app-modules/core/components/icons/repeat.vue';
import Search from '~/app-modules/core/components/icons/search.vue';
import DragDrop from '~/app-modules/core/components/icons/drag-drop.vue';
import Comment from '~/app-modules/core/components/icons/comment.vue';
import LgAreas from '~/app-modules/core/components/icons/lg-areas.vue';
import LgBox from '~/app-modules/core/components/icons/lg-box.vue';
import LgCatch from '~/app-modules/core/components/icons/lg-catch.vue';
import LgFishWeight from '~/app-modules/core/components/icons/lg-fish-weight.vue';
import LgCatchWeight from '~/app-modules/core/components/icons/lg-catch-weight.vue';
import LgTanks from '~/app-modules/core/components/icons/lg-tanks.vue';
import LgStock from '~/app-modules/core/components/icons/lg-stock.vue';
import LgDensity from '~/app-modules/core/components/icons/lg-density.vue';
import LgWeightPerItem from '~/app-modules/core/components/icons/lg-weight-per-item.vue';
import LgSpinner from '~/app-modules/core/components/icons/lg-spinner.vue';
import TableExport from '~/app-modules/core/components/icons/table-export.vue';
import Tip from '~/app-modules/core/components/icons/tip.vue';
import Tech from '~/app-modules/core/components/icons/tech.vue';
import Fish from '~/app-modules/core/components/icons/fish.vue';
import Catch from '~/app-modules/core/components/icons/catch.vue';
import FishWeight from '~/app-modules/core/components/icons/fish-weight.vue';
import Feed from '~/app-modules/core/components/icons/feed.vue';
import Waist from '~/app-modules/core/components/icons/waist.vue';
import Water from '~/app-modules/core/components/icons/water.vue';
import FishAdd from '~/app-modules/core/components/icons/fish-add.vue';
import Star from '~/app-modules/core/components/icons/star.vue';
import StarOutlined from '~/app-modules/core/components/icons/star-outlined.vue';
import Home from '~/app-modules/core/components/icons/home.vue';
import Cloud from '~/app-modules/core/components/icons/cloud.vue';
import Headphones from '~/app-modules/core/components/icons/headphones.vue';
import File from '~/app-modules/core/components/icons/file.vue';
import Document from '~/app-modules/core/components/icons/document.vue';
import DocumentOutlined from '~/app-modules/core/components/icons/document-outlined.vue';
import Information from '~/app-modules/core/components/icons/information.vue';
import InformationOutlined from '~/app-modules/core/components/icons/information-outlined.vue';
import Message from '~/app-modules/core/components/icons/message.vue';
import MessageOutlined from '~/app-modules/core/components/icons/message-outlined.vue';
import Download from '~/app-modules/core/components/icons/download.vue';
import WarehouseAdd from '~/app-modules/core/components/icons/warehouse-add.vue';

export const vuetify = createVuetify({
  ssr: true,
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          link: '#0F5BB3',
          ...generateThemeColorList('beton', Color.rgb(50, 50, 60)),
          ...generateThemeColorList('blue', Color.rgb(35, 160, 225)),
          ...generateThemeColorList('green', Color.rgb(60, 145, 115)),
          ...generateThemeColorList('red', Color.rgb(205, 85, 100)),
          ...generateThemeColorList('yellow', Color.rgb(242, 176, 73)),
          'yellow-contrast': '#C48621',
        },
        variables: {
          'border-color': '#32323C',
          'border-opacity': 0.12,
          'default-emphasis-opacity': 0.84, // прозрачность всех цветов
          'high-emphasis-opacity': 0.36, // влияет на v-ripple
          'medium-emphasis-opacity': 1, // влияет на opacity иконок в списках
          'disabled-opacity': 0.24,
          'idle-opacity': 0.04,
          'hover-opacity': 0.12,
          'focus-opacity': 0.08,
          'selected-opacity': 0.12,
          'activated-opacity': 0.12,
          'pressed-opacity': 0.12,
          'dragged-opacity': 0.12,
        },
      },
    },
  },
  icons: {
    aliases: {
      'arrow-down': ArrowDown,
      'arrow-left': ArrowLeft,
      'auto-fix': AutoFix,
      'calendar-edit': CalendarEdit,
      'edit-pen': EditPen,
      'label-ammonium': LabelAmmonium,
      'label-hydrogen': LabelHydrogen,
      'label-nitrate': LabelNitrate,
      'label-nitrite': LabelNitrite,
      'label-nitrogen': LabelNitrogen,
      'label-oxygen': LabelOxygen,
      'label-temperature': LabelTemperature,
      'drag-drop': DragDrop,
      'lg-areas': LgAreas,
      'lg-box': LgBox,
      'lg-catch': LgCatch,
      'lg-catch-weight': LgCatchWeight,
      'lg-fish-weight': LgFishWeight,
      'lg-tanks': LgTanks,
      'lg-stock': LgStock,
      'lg-density': LgDensity,
      'lg-weight-per-item': LgWeightPerItem,
      'lg-spinner': LgSpinner,
      'table-export': TableExport,
      'fish-weight': FishWeight,
      'fish-add': FishAdd,
      'star-outlined': StarOutlined,
      box: Box,
      check: Check,
      checkboxIndeterminate: NoIcon,
      checkboxOff: NoIcon,
      checkboxOn: Check,
      close: Close,
      copy: Copy,
      cylinder: Cylinder,
      delete: Delete,
      error: Error,
      logout: Logout,
      math: Math,
      menu: Menu,
      plus: Plus,
      minus: Minus,
      moving: Moving,
      warning: Warning,
      repeat: Repeat,
      search: Search,
      tip: Tip,
      tech: Tech,
      fish: Fish,
      catch: Catch,
      feed: Feed,
      waist: Waist,
      water: Water,
      comment: Comment,
      star: Star,
      home: Home,
      cloud: Cloud,
      headphones: Headphones,
      file: File,
      document: Document,
      'document-outlined': DocumentOutlined,
      information: Information,
      'information-outlined': InformationOutlined,
      message: Message,
      'message-outlined': MessageOutlined,
      download: Download,
      'warehouse-add': WarehouseAdd,
    },
  },
  display: {
    mobileBreakpoint: 'xs',
    thresholds: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
      xxl: 9999,
    },
  },
});

export default defineNuxtPlugin((nuxt) => {
  nuxt.vueApp.use(vuetify);
});
