<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.92876 8.74895C9.44427 8.78535 9.0625 9.18998 9.0625 9.68377V14.0722C9.0625 14.5899 9.48223 15.0097 10 15.0097C10.5178 15.0097 10.9375 14.5899 10.9375 14.0722V9.68377C10.9375 9.18998 10.5557 8.78535 10.0712 8.74895C10.0477 8.74718 10.024 8.74628 10 8.74628C9.97603 8.74628 9.95227 8.74718 9.92876 8.74895ZM10.077 7.49762C10.7315 7.45785 11.25 6.91446 11.25 6.24996C11.25 5.55961 10.6904 4.99997 10 4.99997C9.30964 4.99997 8.75 5.55961 8.75 6.24996C8.75 6.91446 9.26851 7.45785 9.92301 7.49762C9.94847 7.49917 9.97414 7.49995 10 7.49995C10.0259 7.49995 10.0515 7.49917 10.077 7.49762ZM10 16.8749C13.797 16.8749 16.875 13.7969 16.875 9.99993C16.875 6.203 13.797 3.12499 10 3.12499C6.20304 3.12499 3.125 6.203 3.125 9.99993C3.125 13.7969 6.20304 16.8749 10 16.8749ZM10 18.7499C14.8325 18.7499 18.75 14.8324 18.75 9.99993C18.75 5.16748 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16748 1.25 9.99993C1.25 14.8324 5.16751 18.7499 10 18.7499Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
