<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="13"
    fill="none"
    viewBox="0 0 9 13"
  >
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M5.993.11a.84.84 0 0 1 .841.841v5.055a3.449 3.449 0 0 0-1.288.306 2.463 2.463 0 0 0-.918.719L1.893 3.074h-.059v4.19a.846.846 0 0 1-1.69 0V1.082a.972.972 0 0 1 1.773-.55L5.081 5.14h.07V.95c0-.465.377-.842.842-.842Z"
      clip-rule="evenodd"
    />
    <path
      stroke="none"
      stroke-width=".15"
      d="M7.238 9.153c-.098.112-.22.24-.366.38l-1.567 1.45a.591.591 0 0 0 .402 1.025H8.24a.496.496 0 0 0 0-.99H6.766l.613-.6c.335-.306.596-.568.782-.789.187-.222.32-.428.395-.617a1.49 1.49 0 0 0 .115-.572c0-.294-.076-.557-.229-.788a1.52 1.52 0 0 0-.636-.534 2.162 2.162 0 0 0-.942-.193c-.349 0-.66.066-.932.2a1.52 1.52 0 0 0-.636.572 1.47 1.47 0 0 0-.16.366.421.421 0 0 0 .1.41.542.542 0 0 0 .396.168h.072a.52.52 0 0 0 .349-.144c.093-.083.167-.192.223-.297a.548.548 0 0 1 .227-.229.73.73 0 0 1 .354-.08.76.76 0 0 1 .348.077.567.567 0 0 1 .323.541.756.756 0 0 1-.071.327 1.389 1.389 0 0 1-.22.317Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
  stroke: currentColor;
}
</style>
