import { TokensStore } from '~/app-modules/auth/stores/tokens.store';
import { UserStore } from '~/app-modules/user/stores/user.store';
import { LocalizationsStore } from '~/app-modules/core/stores/localizations.store';
import { MetricsService } from '~/app-modules/metrics/services/metrics.service';

// Плагин инициализации IOC зависимостей при старте приложения.
// Требуется указывать контейнеры имеющие watchers, useCookies и т.д.
// что бы в момент их получения они были корректно инициализированы
//
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:beforeMount', (_vueApp) => {
    useIOC(TokensStore);
    useIOC(UserStore);
    useIOC(LocalizationsStore);
    useIOC(MetricsService);
  });
});
