<template>
  <v-dialog
    v-model="visible"
    :persistent="true"
    class="align-end"
    width="auto"
    data-test-id="cookie-confirm-dialog"
  >
    <x-card
      class="pa-2"
      content-color="beton"
    >
      <div class="d-flex flex-column flex-sm-row flex-no-wrap justify-space-between">
        <v-img
          class="mt-sm-2 mx-auto"
          height="100"
          min-width="64"
          src="/cookie.png"
          width="160"
        />
        <div>
          <v-card-title class="text-center text-sm-left">Cookie-файлы на сайте</v-card-title>

          <v-card-text>
            <p
              :class="$vuetify.display.smAndUp ? 'text-body-1' : 'text-body-2'"
              class="text-justify"
            >
              Мы используем файлы <b>«Cookie»</b> и иные аналогичные/похожие технологии для
              проведения аналитических и статистических исследований функционирования Цифровой
              платформы Fishplan, анализа использования Вами Цифровой платформы Fishplan, а также
              для улучшения производительности Цифровой платформы Fishplan.
            </p>
            <p
              :class="$vuetify.display.smAndUp ? 'text-body-1' : 'text-body-2'"
              class="text-justify pt-2"
            >
              Вы можете отключить прием файлов <b>«Cookie»</b> и иных аналогичных/похожих технологий
              в настройках своего браузера, однако в этом случае часть функционала может стать
              недоступной. Нажимая на кнопку <b>«ПРИНЯТЬ»</b>, Вы свободно, своей волей и в своем
              интересе предоставляете Администратору согласие на обработку файлов <b>«Cookie»</b> и
              данных, полученных с использованием иных аналогичных/похожих технологий, являющихся
              персональными данными, на условиях
              <a
                class="text-link"
                href="https://home.fishplan.ru/Politika_konfidencialnosti_FISHPLAN.pdf"
                rel="noreferrer"
                target="_blank"
              >
                Политики конфиденциальности</a
              >
            </p>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <x-button
              label="Принять"
              class="flex-0-0"
              min-width="200"
              data-test-id="btn-cookie-confirm"
              @click="onConfirm"
            />
          </v-card-actions>
        </div>
      </div>
    </x-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ConfirmStatus } from '~/app-modules/core/enums/ConfirmStatus';

const visible = ref(false);

let resolveFn = (_status: ConfirmStatus) => {};

function onConfirm() {
  visible.value = false;
  nextTick(() => {
    resolveFn(ConfirmStatus.confirmed);
  });
}

function open(): Promise<ConfirmStatus> | void {
  visible.value = true;

  return new Promise((resolve) => {
    resolveFn = resolve;
  });
}

defineExpose({ open });
</script>

<style lang="scss" scoped></style>
