import { z } from 'zod';

export const EmployeeForDescriptionDtoSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  middleName: z.string().nullable(),
});

export type EmployeeForDescriptionDto = z.infer<typeof EmployeeForDescriptionDtoSchema>;
